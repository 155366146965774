import React from "react";
import  './RoundAnalytics.styles.scss';
import RoundNote from '../RoundNote/RoundNote.component';


export default function RoundAnalytics(props) {
  return(
      <div className="cardList">
          {(props.roundObjects.slice().reverse()).map( (element,key) => 
            <div  key = {key}>
                <RoundNote site = {element.site} time = {element.time}  winCondition = {element.winCondition} round = {element.round}/> 
            </div>
          )}
      </div>
    );
  }