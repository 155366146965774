import React from "react";
import  './RoundNote.styles.scss';
export default function RoundNote(props) {

  
  function timeHelper(time){
    if( (parseInt(time % 60) < 10)){
        return "0" + parseInt(time % 60);
    }
    else{
      return parseInt(time % 60); 
    }
  }

  function cardRules(rule){
      if(rule ==="A"){
        return "noteItemA";
      }
      else if(rule ==="B"){
        return "noteItemB";
      }
      else{
        return "noteItemC";
      }
  }

  return(
      <div className="noteGap">
          <div className={cardRules(props.site)}>
              <div className="header">
                {parseInt( (props.time) / 60)  + ":" + timeHelper(props.time)}
              </div>
              Site : {props.site ? props.site : "no site selected"} <br></br>
              End : {props.winCondition ? props.winCondition : "no condition selected"} <br></br>
              Round : {props.round}
          </div>
      </div>
    );
  }