import React from "react";
import { Chart } from "react-google-charts";
import  './PieChart.styles.scss';


export default function RoundPieChart(props) {
  
  return(
      
    <div >
      
      <div className="chart">
        <Chart
        
          width={'100%'}
          height={'100%'}
          chartType="PieChart"
          loader={<div>Loading Chart</div>}
          data={[
            ['Site', 'Times Pushed'],
            ['A site', props.aSite],
            ['B Site', props.bSite],
            ['C Site', props.cSite],
          ]}
          options={{
            slices: {
              0: { color: '#250777' },
              1: { color: 'purple' },
              2: { color: 'rgb(71, 71, 238)' },
            },
            backgroundColor: 'black',
          }}
          rootProps={{ 'data-testid': '1' }}
        />

        </div>
      </div>
    );
  }