import React from "react";
import  "./CharacterStats.styles.scss";
import {Row,Col} from "react-bootstrap";

export default function CharacterStats(props) {
  return(
    <div >
      <br />
        <Row xs={1} md={3} className="g-3">

          <Col></Col>
          <Col></Col>
          <Col></Col>

        </Row>
      <br />
    </div> 
    );
  }
  