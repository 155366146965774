import React from "react";
import  "./AboutUs.styles.scss";
export default function AboutPage(props) {

  return (
  <div>
      <div className="text">
        <p>
         Hey im a solo developer feel free to add me in game nap#3489 to let me know you have actually found this.
         work on this site has resumedas of 2/8/2022, been busy in real life.
         <br />
          Its always great to know if im helping or what you would like to see added in future.
         
         <br />

         For now im just building out tools for the community one graph at a time 
         </p>
      </div>
  </div> 
  );
}

