import React from "react";
import RotationMap from "../../components/RotationMap/RotationMap.component";
import  "./Rotation.styles.scss";


export default function Rotation(props) {

  return (
  <div>
    <div className="rotationMap">
        <RotationMap />
    </div>
  </div> 
  );
}

