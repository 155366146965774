import React from 'react';
import './Coordinate.styles.scss'
import Timer from "../Timer/Timer.Component"
import WeaponMovement from '../WeaponMovement/WeaponMovement.component';

export default function Coordinate( {time, start, end}){
    var rifleSpeed = (900/712);
    var heavySpeed = (927/712);
 
    return(
        <div className="inlineGrid">
            <br />
            <div className = "coordinatesBox">
                <h3> Coordinates</h3>
                <p className="element">Start: {start != null ? start: 0 }</p>
                <p className="element">End: {end!= null ? end: 0}</p>
            </div>
            
            <WeaponMovement name = "Knife" time = {time} />
            <WeaponMovement name = "Pistol & Rifle" time = {time * rifleSpeed} />
            <WeaponMovement name = "Odin & OP" time = {time * heavySpeed} />
            <Timer time = {time}/>
            
      </div>
      
    );
}