import './App.css';
import NavBar from "./components/NavBar/NavBar.component";
import StatsRouter from './statsRouter/StatsRouter.component';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
      
      <div className="MainPage">
      <header>
      </header>
      <NavBar></NavBar>
      
        
        <StatsRouter></StatsRouter>
        
        </div>
        
    </div>
  );
}

export default App;
