import React, { useState } from 'react';
import  './WhiteBoard.styles.scss';
import CanvasDraw from "react-canvas-draw";
import { Button } from "react-bootstrap";


export default function WhiteBoard(props) {
    
    const [value, setValue] = useState(0); // integer state

    function useForceUpdate(){
        return () => setValue(value => value + 1); // update the state to force render
    }
    
  return(
    <div>
          <div className="scoreBoard">
            <div className="attacker">{props.attacker} </div> <div className="attacker"> - </div> <div className="defender">{props.defender} </div>
          </div>

          <div className={props.map}>
            <CanvasDraw brushRadius="1" key = {value} className ="whiteBoard" brushColor="purple"/> 
          </div>
              
          <Button onClick={useForceUpdate()} className="resetButton">Reset Drawings</Button>  
    </div>
    );
  }