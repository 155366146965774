import {React} from 'react';
import './WeaponMovement.styles.scss';
export default function WeaponMovement({time,name}) {

  return (
    <div className = "padding">
       <div className="box" key = {name}>
                <p className="element">{name} : {parseInt((time != null ? time: 0))}s</p>
                <p className="element">{name} : {parseInt((time != null ? time: 0) * 1.6)}s</p>
        </div>
    </div>
  );
  
} 