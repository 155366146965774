import React from 'react';
import Button from 'react-bootstrap/Button';
import "./SoundCard.styles.scss";
import phase1 from "./SoundCardResources/phase1.mp3";
import phase2 from "./SoundCardResources/phase2.mp3";
import phase3 from "./SoundCardResources/phase3.mp3";
import phase4 from "./SoundCardResources/phase4.mp3";


function SoundCard() {

  let bombPhase1 = new Audio(phase1);
  let bombPhase2 = new Audio(phase2);
  let bombPhase3 = new Audio(phase3);
  let bombPhase4 = new Audio(phase4);

  const playPhase1 = () => {
    bombPhase1.play()
  }
  const playPhase2 = () => {
    bombPhase2.play()
  }
  const playPhase3 = () => {
    bombPhase3.play()
  }
  const playPhase4 = () => {
    bombPhase4.play()
  }
  

  return (
    <div className="titleCard">
      <h3> Bomb Sound References </h3>
      <div>Phase 1:  0-25 Seconds, 1 beep per second.</div>
      <div>Phase 2: 25-35 Seconds, 2 beeps per second.</div>
      <div>Phase 3: 35-40 Seconds, 4 beeps per second</div>
      <div>Phase 4: 40-45 Seconds, 8 beeps per second</div>

      <Button variant="outline-primary" onClick={playPhase1}>Phase 1</Button>
      <Button variant="outline-success" onClick={playPhase2}>Phase 2</Button>
      <Button variant="outline-warning" onClick={playPhase3}>Phase 3</Button>
      <Button variant="outline-danger" onClick={playPhase4}>Phase 4</Button>
    </div>
  );
}

export default SoundCard;