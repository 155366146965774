import {React,useState} from "react";
import L from "leaflet";
import { MapContainer, ImageOverlay, Marker, Popup } from "react-leaflet";
import { Button} from "react-bootstrap";
import "./MarkedImage.styles.scss";
import "leaflet/dist/leaflet.css";
import Haven from "./MarkedImageResources/Haven.png";
import HavenTimings from "./MarkedImageResources/HavenTimings.json";
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import Timer from "../Timer/Timer.Component";
import { Row,Col,} from "react-bootstrap";

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconAnchor:   [12, 41], // point of the icon which will correspond to marker's location
});

L.Marker.prototype.options.icon = DefaultIcon;


var bounds = [
  [100, 0],
  [0, 100],
];


const App = () => {

   const [start, setStart] = useState("");
   const [end, setEnd] = useState("");

   function setCoordinates(x){

      console.log("point:" +x);

      if(start === ""){
          setStart(x);
      }
      else if (end === ""){
          setEnd(x);
      }
      else{
          setStart(x);
          setEnd("");
      }
  }

  return (

    <div >
      <div className = "choiceOutline">
        <Row md={2} className="g-3">
            <Col>
              <div className = "whiteStub" >Start Point: {start} </div>
              <div className = "whiteStub">End Point: {end} </div>
              <Button   id="A_site" 
                      size="lg"
                      variant="danger"
                      name="ASite" 
                      value="A"   
                      onClick={e => console.log("getting site")} >Get Timing</Button>{' '}
            </Col>
            <Col>
              <Timer time={10}></Timer>
            </Col>
          </Row>
      </div>

      <MapContainer
        className=""
        crs={L.CRS.Simple}
        bounds={bounds}
        maxZoom={4.9}
        onzoomstart={(e) => console.log(e)}
        attributionControl={false}
      >
         {HavenTimings.map(Location=>{
                    return (
                     <Marker position={[Location.position.x, Location.position.y]} className ="backgroundtest" eventHandlers={{click: (e) => {setCoordinates(Location.name) },}}>
                              <Popup > {Location.name}</Popup>
                     </Marker>
                    )}
                  )
                }
        <ImageOverlay url={Haven} bounds={[[100, 0],[0, 125],]} />
      </MapContainer>
    </div>
  );
};

export default App;