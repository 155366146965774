import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import "../NavBar/NavBar.styles.scss";

export default function NavBar(){

    return(
      <Navbar bg="black" variant="dark" expand="lg" className="navbar">
      <Navbar.Brand href="/">Valorant Notes</Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link href="/">Home</Nav.Link>
          <Nav.Link href="/inputStats">Note_Page</Nav.Link>
          <Nav.Link href="/Rotation">Rotation_Tracker</Nav.Link>
          <Nav.Link href="/globalStats">Global_Stats</Nav.Link>
          <Nav.Link href="/about">About_Us</Nav.Link>
          {/*<NavDropdown title="Agent Stats" id="basic-nav-dropdown">
          {(Agents.map( (agent) =>  <NavDropdown.Item href={"/agentStats/"+agent.name} key={agent.name}>{agent.name}</NavDropdown.Item>))}
            <NavDropdown.Divider />
            <NavDropdown.Item href="#action/3.4">Coming Soon</NavDropdown.Item>
          </NavDropdown>*/}
        </Nav>
        <Form inline>
          <FormControl type="text" placeholder="Example#1234" className="mr-sm-2" />
          <Button variant="outline-danger">Search Player Stats</Button>
        </Form> 
      </Navbar.Collapse>
    </Navbar>
    );
}