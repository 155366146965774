import React, { useState } from "react";
import RoundAnalytics from "../../components/RoundAnalytics/RoundAnalytics.component";
import RoundPieChart from "../../components/PieChart/PieChart.component";
import RoundHistogram from "../../components/Histogram/RoundHistogram.component";
import WhiteBoard from "../../components/WhiteBoard/WhiteBoard.component";
import axios from 'axios';
import Agents from "./InputPageResources/AgentSelectResources/AgentSelect.json";
import Maps from "./InputPageResources/AgentSelectResources/Maps.json"
import { Button, ButtonGroup, Dropdown } from "react-bootstrap";
import  "./InputPage.styles.scss";
import AgentSelect from "../../components/AgentSelect/AgentSelect.component";

export default function InputPage(props) {

  const [site, setSite] = useState("A");
  const [map, setMap] = useState("Haven");
  const [time, setTime] = useState(0);
  const [totalTime, setTotalTime] = useState(0);

  const [round, setRound] = useState(1);
  const [roundObjects, setRoundObjects] = useState([]);
  const [defender, setDefender] = useState(0);
  const [attacker, setAttacker] = useState(0);
  const [winCondition, setWinCondition] = useState("Attacker");

  const [aSiteCount, setASiteCount] = useState(0);
  const [bSiteCount, setBSiteCount] = useState(0);
  const [cSiteCount, setCSiteCount] = useState(0);

  const [agent, setAgent] = useState("Sova");
 

  const handleSubmit = (evt) => {

    evt.preventDefault();
    if(site === "c" || site === "C"){ setCSiteCount(cSiteCount+1);}
    else if(site === "b" || site === "B"){setBSiteCount(bSiteCount+1);}
    else{setASiteCount(aSiteCount+1);}

    var tempTime = parseInt(totalTime) + parseInt(time); // set time for summarization
    setTotalTime(tempTime);
    updateRounds(round);
    
    const newRound = {
      round: round,
      site: site,
      time: time,
      map: map,
      winCondition:winCondition,

      sites: {
        A: aSiteCount,
        B: bSiteCount,
        C: cSiteCount,
      },
    }
    // add this new round to the list of existing rounds
    const newRoundObjects = roundObjects.slice();
    newRoundObjects.push(newRound);
    setRoundObjects(newRoundObjects);
  }

  function updateRounds(){

    var tempRound = round+1; // set time for summarization
    setRound(tempRound);

    postRounds();

    if(round === 12){

   
     var swapDefender = defender;

    if(winCondition === "Attacker" || winCondition === "Site"){
          var swapAttacker = attacker;
          swapDefender = defender;
          setDefender(swapAttacker+1);
          setAttacker(swapDefender);
          return;
    }
      else{
        swapAttacker = attacker;
        swapDefender = defender;
        setDefender(swapAttacker);
        setAttacker(swapDefender+1);
        return;

      } 
    }

    if(winCondition === "Attacker" || winCondition === "Site"){
      var tempAttacker = attacker + 1;
      setAttacker(tempAttacker);
    }
    else{
      var tempDefender = defender + 1;
      setDefender(tempDefender);
    }
  }
  
  function postRounds(){
    axios.post('https://valorantnotes.herokuapp.com/rounds', {
      round: round.toString(),
      site: site.toString(),
      time: time.toString(),
      agent: agent.toString(),
      map: map.toString()
    });
  }

  function timeHelper(time){
    if( (parseInt((time) % 60) < 10)){
        return "0" + parseInt((time) % 60);
    }
    else{
      return parseInt((time) % 60); 
    }
  }


  return (
  <div>
    <div className="Grid-container">
        
        {/*the input form div*/}
        <div className = "input">
          <div className="Inputform">
        <form onSubmit={handleSubmit}>


        <div className="CharacterCard">
          <AgentSelect agent={agent} />
      
            {/*buttongroup for map selection */}
            <Dropdown>
              <Dropdown.Toggle variant="primary" id="dropdown-basic"> Select Your Map </Dropdown.Toggle>
              <Dropdown.Menu>
                  {(Maps.map( (map) => <Dropdown.Item value="test"  onClick={e => setMap(map.name)}>{map.name}</Dropdown.Item>))}
              </Dropdown.Menu>
            </Dropdown>

            {/*buttongroup for agent selection */}
            <Dropdown>
              <Dropdown.Toggle variant="primary" id="dropdown-basic">Select Your Agent</Dropdown.Toggle>
              <Dropdown.Menu>
                  {(Agents.map( (agent) => <Dropdown.Item value="test"  onClick={e => setAgent(agent.name)}>{agent.name}</Dropdown.Item>))}
              </Dropdown.Menu>
            </Dropdown>
        </div>


            <br /><br />
              {/*site selection and push timing */}
              <div>
                <label>
                {/*buttongroup for site selection */}
                  <Button   id="A_site" 
                    size="lg"
                    variant="outline-primary"
                    name="ASite" 
                    value="A"   
                    onClick={e => setSite(e.target.value)} >A Site</Button>{' '}
                  <Button   id="B_site" 
                    size="lg"
                    variant="outline-primary"
                    name="BSite" 
                    value="B"   
                    onClick={e => setSite(e.target.value)} >B Site</Button>{' '}
                  <Button   id="C_site" 
                    size="lg"
                    variant="outline-primary"
                    name="CSite" 
                    value="C"   
                    onClick={e => setSite(e.target.value)} >C Site</Button>{' '}

                 {/*slider for round push timing */}
                <p>Round Time: {parseInt( time / 60)  + ":" + timeHelper(time)}</p>
                <input type="range" min="1" max="120" onClick={e => setTime(e.target.value)}/>
                </label>
              </div>
           
                
          {/* conclusion info*/}
            <div>
              {/*buttongroup for defender win condition */}
              <ButtonGroup aria-label="First group">
                  <Button   id="A_site" 
                    size="sm"
                    variant="outline-primary"
                    name="Timeout" 
                    className = "site" 
                    value="Timeout"   
                    onClick={e => setWinCondition(e.target.value)} >Defender Timeout</Button>{' '}
                   <Button   id="B_site" 
                    size="sm"
                    variant="outline-primary"
                    name="Defender" 
                    className = "site" 
                    value="Defender"   
                    onClick={e => setWinCondition(e.target.value)}> Defender Eliminaton </Button>{' '}
                    <Button   id="C_site" 
                    size="sm"
                    variant="outline-primary"
                    name="Defuse" 
                    className = "site" 
                    value="Defuse"   
                    onClick={e => setWinCondition(e.target.value)}> Defender Defuse </Button>{' '}
                </ButtonGroup>
              {/*buttongroup for Attacker win condition */}
              <ButtonGroup aria-label="First group">
                  <Button   id="A_site" 
                    size="sm"
                    variant="outline-danger"
                    name="Attacker" 
                    className = "site" 
                    value="Attacker"   
                    onClick={e => setWinCondition(e.target.value)} >Attacker Elimination</Button>{' '}
                   <Button   id="B_site" 
                    size="sm"
                    variant="outline-danger"
                    name="Site" 
                    className = "site" 
                    value="Site"   
                    onClick={e => setWinCondition(e.target.value)} >Attacker Site Victory</Button>{' '}
                </ButtonGroup>
                <br />
                <Button type="submit" value="Submit" > Submit Round</Button>
        </div>
            
        </form>
              <div>
                <RoundAnalytics roundObjects={roundObjects} > </RoundAnalytics>
              </div>
          </div>
        </div>

        {/* the whiteboard div*/}
        <div className ="whiteboard">
          <WhiteBoard map={map} round = {round} attacker = {attacker} defender = {defender}></WhiteBoard>
          <div className="mobile-map">
          <Dropdown>
              <Dropdown.Toggle variant="primary" id="dropdown-basic"> Select Your Map </Dropdown.Toggle>
              <Dropdown.Menu>
                  {(Maps.map( (map) => <Dropdown.Item value="test"  onClick={e => setMap(map.name)}>{map.name}</Dropdown.Item>))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>

        {/*graphs and charts*/}
        <div className = "Analytics"> 
          <div>
            <RoundPieChart aSite = {aSiteCount}  bSite = {bSiteCount}  cSite = {cSiteCount}> </RoundPieChart>
          </div>
          <div>
            <RoundHistogram roundObjects={roundObjects} totalTime={totalTime}> </RoundHistogram>
          </div>
        </div>
    </div>
  </div>

  );
}