import React from "react";
import  "./MapSummary.styles.scss";
import { Row,Col,} from "react-bootstrap";
import MapPan from "../../components/MapPan/MapPan.component";
import CommonTimings from "../../components/CommonTimings/CommingTimings.component";
import SpikeTimer from "../../components/SpikeTimer/SpikeTimer.component";
import SoundCard from "../../components/SoundCard/SoundCard.component";

export default function MapSummaryPage(props) {
  return(
    <div >
      <br />
        <Row xs={1} md={3} className="g-3">
          <Col>
            <CommonTimings  Map= {props.Map == null ? "Ascent" : props.Map}/>
            <SpikeTimer /> 
            <SoundCard />
          </Col>
          <Col>
            <MapPan Map= {props.Map == null ? "Ascent" : props.Map} />
          </Col>
          <Col>
          </Col>
        </Row>
      <br />
    </div> 
    );
  }
  

