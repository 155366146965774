import React from "react";
import  "./MapStats.styles.scss";
import { Card,Button, Col, Row} from "react-bootstrap";
import Ascent from "./MapStatsResources/ascent.jpg";
import Bind from "./MapStatsResources/bind.jpg";
import Breeze from "./MapStatsResources/breeze.png";
import Haven from "./MapStatsResources/haven.jpg";
import Icebox from "./MapStatsResources/icebox.jpg";
import Split from "./MapStatsResources/split.jpg";

export default function MapStatsPage(props) {
  return (
    <div >
      <br />
    <Row xs={1} md={3} className="g-3">
      <Col >
        <Card className="bg-dark text-white">
          <Card.Img src={Ascent} alt="Card image" />
          <Card.ImgOverlay>
            <Card.Title>Ascent</Card.Title>
            <Card.Text>Most Common Site: TBD</Card.Text>
            <Card.Text> Most Common Timing: TBD</Card.Text>
            <Button variant="primary" href="/maps">Get More Stats</Button>
          </Card.ImgOverlay>
        </Card>
      </Col>
      <Col>
        <Card className="bg-dark text-white">
            <Card.Img src={Bind} alt="Card image" />
            <Card.ImgOverlay>
              <Card.Title>Bind</Card.Title>
              <Card.Text>Most Common Site: TBD </Card.Text>
              <Card.Text> Most Common Timing: TBD</Card.Text>
              <Button variant="primary" href="/maps">Get More Stats</Button>
            </Card.ImgOverlay>
          </Card>
      </Col>
      <Col>
          <Card className="bg-dark text-white">
          <Card.Img src={Haven} alt="Card image" />
          <Card.ImgOverlay>
            <Card.Title>Haven</Card.Title>
            <Card.Text>Most Common Site: TBD </Card.Text>
            <Card.Text> Most Common Timing: TBD</Card.Text>
            <Button variant="primary" href="/maps">Get More Stats</Button>
          </Card.ImgOverlay>
        </Card>
      </Col>
    </Row>
      <br />
      <Row xs={1} md={3} className="g-3">
      <Col >
      <Card className="bg-dark text-white">
            <Card.Img src={Breeze} alt="Card image" />
            <Card.ImgOverlay>
              <Card.Title>Breeze</Card.Title>
              <Card.Text>Most Common Site: TBD </Card.Text>
              <Card.Text> Most Common Timing: TBD</Card.Text>
              <Button variant="primary" href="/maps">Get More Stats</Button>
            </Card.ImgOverlay>
          </Card>
      </Col>
      <Col>
      <Card className="bg-dark text-white">
          <Card.Img src={Icebox} alt="Card image" />
          <Card.ImgOverlay>
            <Card.Title>Icebox</Card.Title>
            <Card.Text>Most Common Site: TBD </Card.Text>
            <Card.Text> Most Common Timing: TBD</Card.Text>
            <Button variant="primary" href="/maps">Get More Stats</Button>
          </Card.ImgOverlay>
          
        </Card>
      </Col>
      <Col>
      <Card className="bg-dark text-white">
          <Card.Img src={Split} alt="Card image" />
          <Card.ImgOverlay>
            <Card.Title>Split</Card.Title>
            <Card.Text>Most Common Site: TBD </Card.Text>
            <Card.Text> Most Common Timing: TBD</Card.Text>
            <Button variant="primary" href="/maps">Get More Stats</Button>
          </Card.ImgOverlay>
        </Card>
      </Col>
    </Row>
      <br />
    </div> 
    );
  }