import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import InputPage from "../pages/InputPage/InputPage";
import HomePage from "../pages/Home/Home.page";
import CharacterStats from "../pages/CharacterStats/CharacterStats.page";
import MapStatsPage from "../pages/MapStats/MapStats.page";
import Rotation from "../pages/Rotation/Rotation.page";
import MapSummaryPage from "../pages/MapSummaryPage/MapSummary.page";
import AboutPage from "../pages/AboutUs/AboutUs.page";

export default function StatsRouter() {
  return (
    <Router>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route path="/globalStats">
            <MapStatsPage />
          </Route>
          <Route path="/inputStats">
            <InputPage />
          </Route>
          <Route path="/agentStats">
            <CharacterStats />
          </Route>
          <Route path="/Rotation">
            <Rotation/>
          </Route>
          <Route path="/maps">
            <MapSummaryPage />
          </Route>
          <Route path="/about">
            <AboutPage />
          </Route>
          <Route path="/">
            <HomePage />
          </Route>
        </Switch>
    </Router>
  );
}
