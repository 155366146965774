import React from "react";
import { Chart } from "react-google-charts";
import  './CommonTimings.styles.scss';
import MapTimings from "./CommonTimingsResources/timings.json"


export default function CommonTimings(props) {

    function getMap(mapName){
        if(mapName === "Ascent"){
            return MapTimings.map(e=>e.Ascent);
        }
        else if(mapName === "Bind"){
            return MapTimings.map(e=>e.Bind);
        }
        else if(mapName === "Breeze"){
            return MapTimings.map(e=>e.Breeze);
        }
        else if(mapName === "Ascent"){
            return MapTimings.map(e=>e.Icebox);
        }
        else if(mapName === "Split"){
            return MapTimings.map(e=>e.Split);
        }
        else {
            return MapTimings.map(e=>e.Haven);
        }
    }

  return(
   <div className="whitestub">
        <div>Common Map Timings for {props.Map}</div>
        <div>
            {getMap("Ascent").map(e=> e.map(timing => <div>{timing.name} : {timing.timing} seconds</div>))}
        </div>
   </div>
    );
  }