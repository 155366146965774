import React from "react";
import  './MapPan.styles.scss';
import App from '../MarkedImage/MarkedImage.component';

export default function MapPan(props) {

  return(
    <div className="whitestub">
        <div style={{ width: '500px', height: '500px' }}>
                  <App />
          </div>
    </div>
    );
  }