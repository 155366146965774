import React from "react";
import  "./Home.styles.scss";
import { Card,Row,Col,Button } from "react-bootstrap";
import Notes from './HomePageResources/notes.jpg';


export default function HomePage(props) {

  return (
  <div >

      <br />
    <Row xs={1} md={1} className="g-3">
        <Col>
            <Card className="bg-dark text-white">
            <Card.Img src={Notes} alt="Card image" />
            <Card.ImgOverlay>
                <Card.Title>Welcome to Valorant Notes!!!</Card.Title>
                <Card.Text>
                    Play, Draw and Study your way to the top with our suite of completely free tools!
                </Card.Text>

            </Card.ImgOverlay>
            </Card>
        </Col>
    </Row>
    <br />
  <Row xs={1} md={3} className="g-3">
    <Col >
        <Card className="bg-dark text-white">
            <Card.Body>
                <Card.Title>Notes Page</Card.Title>
                <Card.Text>
                Draw on the map and keep track of sites and push timings!
                </Card.Text>
                <Button variant="danger" href="/inputStats">Go Write</Button>
            </Card.Body>
        </Card>
    </Col>
    <Col>
        <Card className="bg-dark text-white">
            <Card.Body>
                <Card.Title>Beta: Rotation Tracker</Card.Title>
                <Card.Text>
                    See and track timings!
                </Card.Text>
                <Button variant="danger" href="/Rotation">Go Track</Button>
            </Card.Body>
        </Card>
    </Col>
    <Col>
        <Card className="bg-dark text-white">
            <Card.Body>
                <Card.Title>Coming Soon: Global Stats Page</Card.Title>
                <Card.Text>
                  See how you stack up against the Immortals!
                </Card.Text>
                <Button variant="danger" href="/globalStats">Go Study</Button>
            </Card.Body>
        </Card>
    </Col>
  </Row>
    <br />
  </div> 
  );
}