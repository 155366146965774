import React from "react";
import { Chart } from "react-google-charts";
import  './RoundHistogram.styles.scss';
import { timeHelper } from "./RoundHistogram";

export default function RoundHistogram(props) {

    let histogramValues = [["round", "time"]];

    props.roundObjects.map(e => histogramValues.push([ "round: " + e.round , e.time]))

  return(

    <div className="chart padding"> 
      <h4 className="histogramHeader">Execution</h4>
      <Chart
          chartType="Histogram"
          data={histogramValues}
          width="100%"
          height="100%"
          options={{
            colors: ['purple'],
            interpolateNulls: true,
            backgroundColor: '#250777',
          }}
          legendToggle
        />
        <h2 className="histogramHeader"> Average Time: <br />
            {parseInt( ((props.totalTime)  / props.roundObjects.length) / 60)  + ":"}
            {timeHelper(props.totalTime,  props.roundObjects.length)}
        </h2>
    </div>
    );
  }