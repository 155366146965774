import React from "react";
import  './AgentSelect.styles.scss';
import { Card } from "react-bootstrap";
import Astra from "../AgentSelect/AgentSelectResources/Astra.png";
import Breach from "../AgentSelect/AgentSelectResources/Breach.png";
import Brimstone from "../AgentSelect/AgentSelectResources/Brimstone.png";
import Cypher from "../AgentSelect/AgentSelectResources/Cypher.png";
import Jett from "../AgentSelect/AgentSelectResources/Jett.png";
import Omen from "../AgentSelect/AgentSelectResources/Omen.png";
import Phoenix from "../AgentSelect/AgentSelectResources/Phoenix.png";
import Raze from "../AgentSelect/AgentSelectResources/Raze.png";
import Sage from "../AgentSelect/AgentSelectResources/Sage.png";
import Sova from "../AgentSelect/AgentSelectResources/Sova.png";
import Viper from "../AgentSelect/AgentSelectResources/Viper.png";
import Killjoy from "../AgentSelect/AgentSelectResources/Killjoy.png";
import Yoru from "../AgentSelect/AgentSelectResources/Yoru.png";
import Skye from "../AgentSelect/AgentSelectResources/Skye.png";
import KAYIO from "../AgentSelect/AgentSelectResources/KAYIO.png";
import Reyna from "../AgentSelect/AgentSelectResources/Reyna.png";

export default function AgentSelect(props) {

  function selectAgent(agent){
    
    if(agent === "Astra"){
      return Astra;
    }
    else if(agent === "Breach"){
      return Breach;
    }
    else if(agent === "Brimstone"){
      return Brimstone;
    }
    else if(agent === "Cypher"){
      return Cypher;
    }
    else if(agent === "Jett"){
      return Jett;
    }
    else if(agent === "Killjoy"){
      return Killjoy;
    }
    else if(agent === "Omen"){
      return Omen;
    }
    else if(agent === "Phoenix"){
      return Phoenix;
    }
    else if(agent === "Raze"){
      return Raze;
    }
    else if(agent === "Reyna"){
      return Reyna;
    }
    else if(agent === "Sage"){
      return Sage;
    }
    else if(agent === "Sova"){
      return Sova;
    }
    else if(agent === "Viper"){
      return Viper;
    }
    else if(agent === "Yoru"){
      return Yoru;
    }
    else if(agent === "Skye"){
      return Skye;
    }
    else if(agent === "KAYIO"){
      return KAYIO;
    }
  }

  return(
    
      <div>
        <Card style={{ width: '27%' }} className="agentSelect">
            <Card.Img variant="top" src={selectAgent(props.agent)} />
        </Card>
    </div>
    );
  }