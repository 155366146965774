import {React} from 'react';
import ReactCountdownClock from 'react-countdown-clock';
import "./Timer.styles.scss";
export default function Timer(props) {
 
  return (
    <div className= "timerBox">
      <ReactCountdownClock seconds={props.time}
                     color = "red"
                     alpha={.5}
                     size={80}
                     weight={0}
                     />

    </div>
  );
  
} 